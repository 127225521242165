import { graphql, useStaticQuery } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';
import React from 'react';

import CtaLink from '../../components/framework/cta-link-mini';

import Link from './link';

const Location = ({ types }) => {
	const { t } = useI18next();

	const result = useStaticQuery(graphql`
		query LocationComponent {
			site {
				siteMetadata {
					cdnUrl
				}
			}
			allLocation(filter: { active: { eq: true } }) {
				nodes {
					address
					email
					image_header
					name
					opening_hours_weekdays
					opening_hours_weekends
					phone_number
					partner {
						id
					}
					products {
						type
					}
					slug
					translation_key
				}
			}
		}
	`);
	const locations = result.allLocation.nodes;
	const site = result.site.siteMetadata;

	return (
		<>
			<div className="row">
				<div className="col-md-6 "></div>
			</div>
			{locations
				.filter((location) => {
					if (location.partner.id != 'zample-nl') {
						return false;
					}
					if (!location.products || !types) {
						return location;
					}
					if (
						location.products.some((product) => {
							return types.includes(product.type);
						})
					) {
						return location;
					}

					return null;
				})
				.map((location, i) => (
					<section key={i}>
						<div className="location-item card bg-light border-0 rounded-0 mb-3">
							<div className="card-body">
								<Link
									className="mb-0 text-decoration-none"
									to={`/locations/${location.slug}`}
								>
									<h3 className="h5 text-dark mb-0">{location.name}</h3>
									<p className="small text-dark">{location.address}</p>
								</Link>

								<div className="row">
									<div className="col-auto">
										<img
											alt=""
											className="location-thumb"
											src={`${site.cdnUrl}${encodeURIComponent(
												location.image_header
											)}?alt=media`}
										/>
									</div>
									<div className="col-12 col-md-8 mt-3 mt-md-0 pe-3">
										<div className="row">
											<div className="col-auto">
												<h3 className="title-small mb-1 text-muted">
													{t('component_location:t1')}
												</h3>
												<p className="nowrap small mb-0 text-dark">
													{t('component_location:t2')}&#160;
													{location.opening_hours_weekdays
														? location.opening_hours_weekdays
														: t('component_location:t7')}
												</p>

												<p className="nowrap small text-dark">
													{t('component_location:t3')}&#160;
													{location.opening_hours_weekends
														? location.opening_hours_weekends
														: t('component_location:t7')}
												</p>
											</div>
											<div className="col-md-6">
												<h3 className="title-small mb-1 text-muted">
													{t('component_location:t4')}
												</h3>
												<p className="small text-dark">
													{t(`${location.translation_key}_information`)}
												</p>
											</div>
										</div>
										<CtaLink
											label={t('component_location:t5')}
											linkUrl="/book"
										/>
										<CtaLink
											label={t('component_location:t6')}
											linkUrl={`/locations/${location.slug}`}
										/>
										<div className="row">
											<div className="col"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				))}
		</>
	);
};

Location.propTypes = {
	types: PropTypes.array,
};

export default Location;
