import PropTypes from 'prop-types';
import React from 'react';

import Link from './link';

import { ArrowRightCircle } from 'react-bootstrap-icons';

const CtaLink = ({ label, linkUrl }) => {
	return (
		<>
			<section>
				<Link aria-current="page" className="text-decoration-none" to={linkUrl}>
					<div className="row align-items-center my-1">
						<div className="col-auto pe-0">
							<div className="small text-primary container-icon text-center">
								<ArrowRightCircle />
							</div>
						</div>
						<div className="col x-small text-decoration-none text-script">
							{label}
						</div>
					</div>
				</Link>
			</section>
		</>
	);
};

CtaLink.propTypes = {
	label: PropTypes.string,
	linkUrl: PropTypes.string,
};

export default CtaLink;
