import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';
import React from 'react';

import Location from '../../components/framework/location';
import Disclaimer from '../../components/framework/disclaimer';

import Framework from '../../components/framework';
import Seo from '../../components/framework/seo';
import withLocation from '../../middleware/withLocation';

const Mottagningar = ({ location }) => {
	const { t } = useTranslation('locations');

	return (
		<Framework location={location}>
			<Seo
				description={t('meta_description')}
				tags={t('meta_keywords')}
				title={t('title')}
			/>

			<div className="container mt-3 pt-lg-5">
				<h1 className="h2">{t('t1')}</h1>
				<div className="my-4">
					<p>{t('t2')}</p>
				</div>
				<Location />
				<Disclaimer />
			</div>
		</Framework>
	);
};

Mottagningar.propTypes = {
	location: PropTypes.object.isRequired,
};

export default withLocation(Mottagningar);

export const query = graphql`
	query {
		locales: allTranslation(filter: { id: { ne: "dummy" } }) {
			...translationFields
		}
	}
`;
